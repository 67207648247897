<template>
  <svg
    width="54"
    height="70"
    viewBox="0 0 54 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.8011 16.9224C22.5712 16.9224 19.1299 20.3637 19.1299 24.5936C19.1299 28.8235 22.5712 32.2649 26.8011 32.2649C31.0311 32.2649 34.4724 28.8235 34.4724 24.5936C34.4724 20.3637 31.0313 16.9224 26.8011 16.9224ZM26.8011 28.6548C24.5618 28.6548 22.74 26.8329 22.74 24.5936C22.74 22.3543 24.5618 20.5324 26.8011 20.5324C29.0404 20.5324 30.8623 22.3543 30.8623 24.5936C30.8623 26.8329 29.0404 28.6548 26.8011 28.6548Z"
      fill="#4874E6"
    />
    <path
      d="M49.8473 43.2027C47.6582 39.8429 45.0234 38.2987 43.6477 37.6767C44.3863 32.9476 44.6343 28.2267 43.9133 24.1964C41.2945 9.55578 28.6106 0.759823 28.0721 0.392382C27.3054 -0.130143 26.2966 -0.130143 25.5299 0.392382C24.9914 0.759588 12.3075 9.55578 9.68895 24.1961C8.95926 28.2764 9.22252 33.0645 9.98262 37.8523C8.53973 38.5695 6.15949 40.1236 4.15306 43.2024C0.382015 48.9898 -0.251285 57.2164 2.27107 67.6534C2.44548 68.3758 2.96471 68.966 3.65882 69.2314C3.91973 69.3311 4.19242 69.3799 4.46393 69.3799C4.91552 69.3799 5.36309 69.2443 5.74515 68.9811L16.7432 61.3963C17.0354 62.1279 17.2277 62.584 17.2789 62.7042C17.6329 63.5371 18.4505 64.0778 19.3553 64.0778H24.5447V67.7442C24.5447 68.9903 25.5549 70.0004 26.801 70.0004C28.0471 70.0004 29.0573 68.9903 29.0573 67.7442V64.0778H34.2467C35.1515 64.0778 35.9691 63.5371 36.3231 62.7042C36.379 62.5726 36.6043 62.0376 36.9446 61.1807L48.2552 68.9811C48.6372 69.2443 49.085 69.3799 49.5364 69.3799C49.8079 69.3799 50.0808 69.3308 50.3415 69.2314C51.0356 68.966 51.5546 68.376 51.7293 67.6534C54.2514 57.2166 53.6183 48.9901 49.8473 43.2027ZM26.801 5.09535C28.2609 6.28134 30.7658 8.51662 33.1936 11.6202H20.4084C22.8367 8.51592 25.3418 6.28087 26.801 5.09535ZM5.96835 63.3457C3.50585 49.7405 8.17605 44.4271 10.8761 42.5503C12.0916 48.0892 13.7685 53.3034 15.1037 57.0455L5.96835 63.3457ZM32.7253 59.5652H29.0573V41.6846C29.0573 40.4385 28.0471 39.4283 26.801 39.4283C25.5549 39.4283 24.5447 40.4385 24.5447 41.6846V59.5652H20.8758C18.8549 54.4428 12.1274 36.1907 14.1308 24.9907C14.7872 21.3212 16.214 18.0432 17.9138 15.23H35.6879C37.3877 18.0432 38.8148 21.3212 39.471 24.9907C41.4718 36.1777 34.7459 54.4397 32.7253 59.5652ZM48.0322 63.3457L38.5773 56.8251C39.9179 53.0475 41.5809 47.8352 42.7763 42.3193C45.3948 43.9703 50.6041 49.135 48.0322 63.3457Z"
      fill="#4874E6"
    />
  </svg>
</template>

<script>
export default {
  name: "RocketIcon",
};
</script>

<style scoped></style>
